<template>
  <div class="Withdraw">
    <van-nav-bar
        title="提现记录"
        left-arrow
        @click-left="onClickLeft"
    />
    <van-list
      v-if="!nondata"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <van-cell-group :border="false" v-for="(item, index) in list" :key="index" @click="order(item.id)">
        <van-cell :border="false" title-class="title_class">
          <template #title>
            {{item.applyno}}
          </template>
          <template #right-icon>
            <span>&yen;{{item.realprice}}</span>
          </template>
        </van-cell>
        <van-cell :border="false" title-class="date_class">
          <template #title>
            {{item.createtime_str}}
          </template>
          <template #right-icon>
            <van-tag v-if="item.status == 1" type="warning" size="mini">{{item.status_str}}</van-tag>
            <van-tag v-if="item.status == 2" @click.stop="showAddRealname(item.id,item.err_code,item.realname)" type="primary" size="mini">{{item.status_str}}</van-tag>
            <van-tag v-if="item.status == 3"  type="success" size="mini">{{item.status_str}}</van-tag>
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>
    <van-empty v-if="nondata" description="暂无记录" />

    <!-- 修改真是姓名弹框 -->
    <van-dialog
      title="修改真实姓名"
      v-model="showAddName"
      show-cancel-button
      @confirm="onsubmit"
    >
      <div style="margin: 0 16px;overflow: hidden;" class="dialog_div">
        <van-field
          v-model="realname"
          placeholder="请输入真实姓名"
          :border="false"
          input-align="center"
        />
      </div>
    </van-dialog>
  </div>
</template>
<script>
export default {
  name: "Withdraw",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      list: [],
      CurrOffset: 0,
      CurrSize: 20,
      loading: false, // 本次加载是否完毕
      finished: false, // 是否查询完成
      nondata: false,
      showAddName: false,
      realname: '',
      logid: 0,
    }
  },
  methods: {
    onLoad() {
      let that = null;
      that = this;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "shopsmobile/earning/get_list",
          that.$qs.stringify({
            size: that.CurrSize,
            offset: that.CurrOffset,
          })
        )
        .then(res => {
          console.log(res);
          that.loading = false;
          if (res.data.code == 100000) {
            that.nondata = false;
            if (that.CurrOffset == 0) {
              that.list = res.data.data;
            }else {
              res.data.data.forEach(item => {
                that.list.push(item);
              });
            }

            if (res.data.data.length >= that.CurrSize) {
              that.CurrOffset += that.CurrSize;
              that.finished = false;
            }else {
              that.CurrOffset += res.data.data.length;
              that.finished = true;
            }
          }
        })
        .catch(err => {
          console.log(err);
          that.loading = false;
          that.finished = true;
        })
      
    },

    // 查看订单
    order(id) {
      this.$router.push({
        name: 'Earningorder',
        query: {
          id: id,
          t: new Date().getTime()
        }
      })
    },
    // 返回上一页
    onClickLeft() {
      this.$router.back();
    },
    // 显示填写真实姓名弹框
    showAddRealname(id=0,str='',name='') {
      console.log(id, str, name);
      this.logid = id;
      this.realname = name;
      if (str == 'NAMEMISMATCH') {
        this.showAddName = true;
      }
    },

    // 修改真实姓名
    onsubmit() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "shopsmobile/earning/update_withdraw_realname",
          _that.$qs.stringify({
            "log_id": _that.logid,
            realname: _that.realname
          })
        )
        .then(res => {
          if(res.data.code == 100000) {
            _that.$toast("修改成功");
          }else {
            _that.$toast({
              type: "text",
              message: res.data.msg ? res.data.msg : "修改失败"
            })
          }
        })
    },
  },
}
</script>
<style lang="less">
.Withdraw {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  background-color: #f0f0f0;
  &:last-child {
    padding-bottom: 50px;
  }
  .van-list {
    background-color: #fff;
    .van-cell-group {
      padding: 0;
      .van-cell {
        .van-cell__title {
          flex: 1;
          text-align: left;
        }
        &:last-of-type {
          padding-top: 0;
          border-bottom: 1px solid #eee;
          color: #999;
          font-size: 12px;
        }
        span {
          margin-left: 5px;
        }
      }
    }
  }
  .dialog_div {
    .van-cell__value {
      flex: 1;
    }
  }
}
</style>